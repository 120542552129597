import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./PageTitleHeader.module.css";
import backIcon from "../../assets/back_btn.svg";

export default function PageTitleHeader({
  title,
  backButton = true,
  onClick = null,
  rightButton = null,
}) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.back_button}>
        {backButton && (
          <div className={classes.back_button} onClick={handleOnClick}>
            <img src={backIcon} alt="backIcon" />
          </div>
        )}
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.right_button}>
        {rightButton && <div>{rightButton}</div>}
      </div>
    </div>
  );
}
