import { SET_SIGNUP_MODAL } from "../../Constants";

export const SignUpReducer = (state = { signupShow: false }, action) => {
  switch (action.type) {
    case SET_SIGNUP_MODAL:
      return { ...action.payload };
    default:
      return state;
  }
};
