
const getAverageRating = (ratings) => {
    let total = 0;
    ratings?.forEach(item => {
        total += item.rate;
    })
    let avg = total / ratings?.length;
    if (isNaN(avg)) {
        return 0;
    }
    return avg.toFixed(1);
}

async function GetAddressByCoordinates({ lat, lon }) {
    let geocoder = new window.google.maps.Geocoder();

    let response = await geocoder.geocode(
        {
            location: {
                lat,
                lng: lon
            }
        },
    )
    if (response?.results[0]) {
        return response?.results[0]?.formatted_address?.split(",")?.splice(0, 4)?.join(",");
    } else {
        return "---";
    }
}

function camelCaseToSentenceCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
}

function trimString(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
}

function stringToColor(string = "") {
    if (!string) return "#000000";
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name = "", w = "102px", fs = "40px") {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: w,
            height: w,
            fontSize: fs,
        },
        children: `${name[0] || ''}`?.toUpperCase(),
    }
}

export { getAverageRating, GetAddressByCoordinates, camelCaseToSentenceCase, trimString, stringToColor, stringAvatar };