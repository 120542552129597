import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const unauthanticatedApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const Logout = async () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(`LOGOUT`);
  }
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/";
};

api.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      Logout();
    }
    return Promise.reject(error);
  }
);

export default api;
