import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import ProgressLoader from "../ProgressLoader";

import {
  SecondStepRegistrationSchema,
  UserRegistrationSchema,
} from "../../Validations";

import { login, signup } from "../../Api/services/auth";

import RadioCheck from "../../assets/radio_check.png";
import RadioUnCheck from "../../assets/radio_uncheck.png";
import DocziIcon from "../../assets/mobile.png";
import GoogleIcon from "../../assets/google-icon.svg";
import FacebookIcon from "../../assets/fb-icon.svg";
import AppleIcon from "../../assets/apple-icon.svg";
import backIcon from "../../assets/back_arrow-left.png";

import classes from "./SignupForm.module.css";
import { signInWithPopup, signInWithRedirect } from "firebase/auth";
import { auth, facebookProvider, googleProvider } from "../../Config/firebase";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { checkUsername, getPlayerById } from "../../Api/services/players";
import { VisibilityOffSharp, VisibilitySharp, CheckCircle, RemoveCircle } from "@mui/icons-material";

export default function SignupForm() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({ gender: "male", role: "user" });
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const [isUsernameAvailable, setUsernameAvailable] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation((prevShowPassword) => !prevShowPassword);
  };

  let isAuth = useSelector((state) => state.user);

  const calculateAge = (dob) => {
    let today = new Date();
    let birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return parseInt(age);
  };

  const onSubmit = async (values, type) => {
    setLoading(true);
    setError("");
    if (currentStep === 1) {
      setFormData({ ...values, gender: formData.gender });
      setCurrentStep(2);
    } else {
      let ProfileData =
        type === "google"
          ? { ...values, role: "user" }
          : {
            photo: "",
            age: calculateAge(formData.birthDate),
            gender: formData.gender,
            email: formData.email,
            password: formData.password,
            name: formData.name,
            role: formData.role,
            userName: formData.userName,
          };
      try {
        await signup(ProfileData);

        let res = await login({
          email: formData.email,
          password: formData.password,
        });

        localStorage.setItem(
          "user",
          JSON.stringify({
            ...res.data,
            socialUID: values.uid,
            socialAuth: type === "thirdParty",
          })
        );
        localStorage.setItem("token", res?.data?.token);


        let user = await getPlayerById(res?.data?.uid);

        // sending email to webview for push notifications
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(user?.email);
        }
        
        dispatch({ type: "SAVE_USER_INFO", payload: user });
      } catch (error) {
        setError(error.response?.data?.message);
      }
    }
    setLoading(false);
  };

  const handleSocialAuth = async (values) => {
    try {
      let res = await login({
        email: values.email,
        password: values.password,
        uid: values.uid,
        name: values.name,
        photo: values.photo,
        role: values.role,
        provider: values.provider,
        socialAuth: true,
      });
      localStorage.setItem(
        "user",
        JSON.stringify({ ...res.data, socialAuth: true, socialUID: values.uid })
      );
      localStorage.setItem("token", res?.data?.token);
      let user = await getPlayerById(res.data?.uid);
      dispatch({ type: "SAVE_USER_INFO", payload: user });
    } catch (error) {
      setError(
        error.response?.data?.message ||
        "Something went wrong. Please try again"
      );
    }
  };

  const handleSignUpWithGoogle = async () => {
    try {
      let res = await signInWithPopup(auth, googleProvider);
      handleSocialAuth({
        email: res?.user?.email,
        password: res?.user?.uid,
        uid: res?.user?.uid,
        name: res?.user?.displayName,
        photo: res?.user?.photoURL,
        provider: "google",
        role: "user",
      });
    } catch (error) {
      setError(error.response?.data?.message);
    }
  };

  const handleSignUpWithFacebook = async () => {
    try {
      let res = await signInWithPopup(auth, facebookProvider);
      handleSocialAuth({
        email: res?.user?.email,
        password: res?.user?.uid,
        uid: res?.user?.uid,
        name: res?.user?.displayName,
        photo: res?.user?.photoURL,
        provider: "facebook",
        role: "user",
      });
    } catch (error) {
      setError(
        error.response?.data?.message ||
        "Email already linked with another account"
      );
    }
  };

  const checkUsernameAvailability = async (username) => {
    if (username === "" || !username) return setUsernameAvailable(null);
    let data = await checkUsername(username);
    if (data?.message === "Username already exists.") {
      setUsernameAvailable(false);
    } else {
      setUsernameAvailable(true);
    }
  };

  return (
    <>
      {currentStep === 1 ? (
        <div className={classes.form}>
          <Formik
            initialValues={{
              userName: "",
              email: "",
              password: "",
              passwordConfirmation: "",
              role: "",
            }}
            validationSchema={UserRegistrationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ errors, touched, handleSubmit, handleChange }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={classes.header}>
                  <img src={DocziIcon} alt="logo" style={{ width: "100px" }} />
                  <h2>Welcome!</h2>
                  <p>Sign up to your account</p>
                </div>
                <div className={classes.mb_14}>
                  <label>Username</label>
                  <div style={{ position: "relative" }}>
                    <Field
                      name="userName"
                      type="text"
                      placeholder="choose a username"
                      className={errors.userName && classes.error_border}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e);
                        checkUsernameAvailability(e.target.value);
                      }}
                    />
                    {isUsernameAvailable === false ?
                      <RemoveCircle style={{ position: "absolute", right: 10, top: 8, color: "#ff0000" }} /> :
                      isUsernameAvailable && <CheckCircle style={{ position: "absolute", right: 10, top: 8, color: "#00b894" }} />
                    }
                  </div>
                  {errors.userName ? (
                    <div className={classes.errorMessage}>{errors.userName}</div>
                  ) : null}
                </div>
                <div className={classes.mb_14}>
                  <label>Email</label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    className={errors.email && classes.error_border}
                    autoComplete="off"
                  />
                  {errors.email ? (
                    <div className={classes.errorMessage}>{errors.email}</div>
                  ) : null}
                </div>
                <div className={classes.mb_14}>
                  <label>Password</label>
                  <div style={{ position: "relative" }}>
                    <Field
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      className={errors.password && classes.error_border}
                      autoComplete="off"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "44%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffSharp />
                      ) : (
                        <VisibilitySharp />
                      )}{" "}
                    </span>
                  </div>
                  {errors.password ? (
                    <div className={classes.errorMessage}>
                      {errors.password}
                    </div>
                  ) : null}
                </div>
                <div className={classes.mb_14}>
                  <label>
                    Repeat Password
                  </label>
                  <div style={{ position: "relative" }}>
                    <Field
                      name="passwordConfirmation"
                      autoComplete="off"
                      type={showPasswordConfirmation ? "text" : "password"}
                      placeholder="Repeat password"
                      className={
                        errors.passwordConfirmation && classes.error_border
                      }
                    />
                    <span
                      onClick={togglePasswordConfirmationVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "44%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPasswordConfirmation ? (
                        <VisibilityOffSharp />
                      ) : (
                        <VisibilitySharp />
                      )}{" "}
                    </span>
                  </div>
                  {errors.passwordConfirmation ? (
                    <div className={classes.errorMessage}>
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </div>
                <div className={classes.mb_14}>
                  <label>You are a:</label>
                  <Field as="select" name="role">
                    <option value="user">Player</option>
                    <option value="referee">Referee</option>
                  </Field>
                  {errors.role ? (
                    <div className={classes.errorMessage}>{errors.role}</div>
                  ) : null}
                </div>
                <button type="submit" className={classes.SignupBtn} disabled={!isUsernameAvailable} style={{ opacity: !isUsernameAvailable ? 0.5 : 1 }}>
                  Next
                </button>
              </Form>
            )}
          </Formik>
          <div className={classes.divider}>
            <div>or</div>
          </div>
          <button
            onClick={handleSignUpWithGoogle}
            className={classes.googleBtn}
          >
            <img className={classes.mx1} src={GoogleIcon} alt="Google" />
            <span>Sign up with Google</span>
          </button>

          <button
            onClick={handleSignUpWithFacebook}
            className={classes.facebookBtn}
          >
            <img className={classes.mx1} src={FacebookIcon} alt="Facebook" />
            <span>Sign up with Facebook</span>
          </button>
          <button className={classes.appleBtn}>
            <img className={classes.mx1} src={AppleIcon} alt="Apple" />
            <span>Sign up with Apple</span>
          </button>

          <div className={classes.footer}>
            <p>
              Already have an account?
              <span
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.form}>
          <div
            className={classes.back_button}
            onClick={() => setCurrentStep(1)}
          >
            <div>
              <img src={backIcon} alt="backIcon" />
            </div>
          </div>
          <Formik
            initialValues={{
              name: "",
              birthDate: "",
            }}
            validationSchema={SecondStepRegistrationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ errors, touched, handleSubmit, setFieldValue }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={classes.header}>
                  <img src={DocziIcon} alt="logo" style={{ width: "100px" }} />
                  <h2>Welcome!</h2>
                  <p>Sign up to your account</p>
                </div>
                <div className={classes.mb_14}>
                  <label className={errors.name && classes.errorHighlight}>
                    Name
                  </label>
                  <Field
                    name="name"
                    placeholder="Name"
                    className={errors.name && classes.error_border}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      setFormData({ ...formData, name: e.target.value });
                    }}
                    value={formData.name}
                  />
                  {errors.name ? (
                    <div className={classes.errorMessage}>{errors.name}</div>
                  ) : null}
                </div>
                <div className={classes.mb_14}>
                  <label>Gender at Birth</label>
                  <div className={classes.radioGroup}>
                    <label
                      onClick={() =>
                        setFormData({ ...formData, gender: "male" })
                      }
                      className={classes.radioContainer}
                    >
                      <img
                        src={
                          formData.gender === "male" ? RadioCheck : RadioUnCheck
                        }
                        alt="radio"
                      />
                      <p>Male</p>
                    </label>
                    <label
                      onClick={() =>
                        setFormData({ ...formData, gender: "female" })
                      }
                      className={classes.radioContainer}
                    >
                      <img
                        src={
                          formData.gender === "female"
                            ? RadioCheck
                            : RadioUnCheck
                        }
                        alt="radio"
                      />
                      <p>Female</p>
                    </label>
                  </div>
                </div>
                <div className={classes.mb_14}>
                  <label className={errors.birthDate && classes.errorHighlight}>
                    Date Of Birth
                  </label>
                  <Field
                    name="birthDate"
                    type="date"
                    placeholder="Date of Birth"
                    className={errors.birthDate && classes.error_border}
                    value={formData.birthDate || new Date()}
                    onChange={(e) => {
                      setFieldValue("birthDate", e.target.value);
                      setFormData({ ...formData, birthDate: e.target.value });
                    }}
                  />
                  {errors.birthDate ? (
                    <div className={classes.errorMessage}>
                      {errors.birthDate}
                    </div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className={clsx(classes.SignupBtn, "mt-1")}
                >
                  {loading ? <ProgressLoader /> : "Sign Up"}
                </button>
              </Form>
            )}
          </Formik>
          <div className={classes.divider}>
            <div>or</div>
          </div>
          <button
            onClick={handleSignUpWithGoogle}
            className={classes.googleBtn}
          >
            <img className={classes.mx1} src={GoogleIcon} alt="Google" />
            <span>Sign up with Google</span>
          </button>
          <button
            onClick={handleSignUpWithFacebook}
            className={classes.facebookBtn}
          >
            <img className={classes.mx1} src={FacebookIcon} alt="Facebook" />
            <span>Sign up with Facebook</span>
          </button>
          <button className={classes.appleBtn}>
            <img className={classes.mx1} src={AppleIcon} alt="Apple" />
            <span>Sign up with Apple</span>
          </button>
          <div className={classes.footer}>
            <p>
              Already have an account?{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
