import api, { unauthanticatedApi } from "..";

export const getAllBookings = async (filters) => {
  try {
    if (filters) {
      const res = await api.get(`/bookings?${new URLSearchParams(filters)}`);
      return res.data;
    }
    const res = await unauthanticatedApi.get("/bookings");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getBookingById = async (id) => {
  try {
    const res = await api.get(`/bookings/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBookingByUUID = async (id) => {
  try {
    const res = await api.get(`/bookings-local/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createBooking = async (data) => {
  try {
    const res = await api.post("/payment/booking", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateBooking = async (id, data) => {
  try {
    const res = await api.put(`/bookings/${id}`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getBookingsByPitch = async (pitchId) => {
  try {
    const res = await api.get(`/pitch/bookings/${pitchId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getBookingsByUser = async (userId) => {
  try {
    const res = await api.get(`/user-bookings`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const CancelBooking = async (body) => {
  try {
    const res = await api.post(`/payment/cancel-booking`, body);
    return res;
  } catch (error) {
    throw error;
  }
};

export const LeaveMatch = async (body) => {
  try {
    const res = await api.post(`/payment/cancel-player-booking`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const joinMatch = async (body) => {
  try {
    const res = await api.post(`/join/match`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const joinMatchAsReferee = async (matchId) => {
  try {
    const response = await api.post(`/referee-join-match`, { matchId });
    return response.data;
  } catch (error) {
    console.error("Error joining match as referee:", error);
    throw error;
  }
};

export const postBookingById = async (id) => {
  try {
    const response = await api.get(`/bookings/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error posting to the booking endpoint:", error);
    throw error;
  }
};

export const recordPlayerScore = async (requestData) => {
  try {
    const response = await api.post(`/referee-record-score`, requestData);
    return response.data;
  } catch (error) {
    console.error("Error recording player score:", error);
    throw error;
  }
};

export const recordPlayerAward = async (playerId, bookingId, award) => {
  try {
    const response = await api.post(`/referee-record-award`, {
      playerId,
      bookingId,
      awards: award,
    });
    return response.data;
  } catch (error) {
    console.error("Error recording player award:", error);
    throw error;
  }
};

export const recordPlayerCard = async (playerId, bookingId, cardType) => {
  try {
    const response = await api.post(`/referee-give-card`, {
      playerId,
      bookingId,
      type: cardType,
    });
    return response.data;
  } catch (error) {
    console.error("Error giving player card:", error);
    throw error;
  }
};

export const banPlayer = async (playerId, bookingId, reason, until) => {
  try {
    const response = await api.post(`/referee-ban-player`, {
      playerId,
      bookingId,
      reason,
      until,
    });
    return response.data;
  } catch (error) {
    console.error("Error banning player:", error);
    throw error;
  }
};
