import api from "..";

export const verifyProfile = async (formData) => {
    try {
        const response = await api.post('/User/verify', formData);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getPlayerById = async (id) => {
    try {
        const response = await api.get(`/User/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkUsername = async (username) => {
    try {
        const response = await api.post('/check-username', { userName: username });
        return response?.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPlayersByUsername = async (username) => {
    try {
        const response = await api.get(`/filter-user?q=${username}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const updatePlayer = async (uid, body) => {
    try {
        const response = await api.put(`/User/${uid}`, body);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const ScanPlayer = async (data) => {
    try {
        const response = await api.post(`/scan-player`, data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getAllAdminsWithPitches = async () => {
    try {
        const response = await api.get(`/all/Users?role=admin`);
        return response.data;
    } catch (error) {
        throw error.response.data;        
    }
}