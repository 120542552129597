import api from "..";

export const ScanPlayerByQR = async (data) => {
    try {
        const response = await api.post('/scan-player', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const ScanPlayerByTicketId = async (data) => {
    try {
        const response = await api.post('/ticket/scan-player', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const recordScore = async (data) => {
    try {
        const response = await api.post('/referee-record-score', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const recordRedCard = async (data) => {
    try {
        const response = await api.post('/referee-ban-player', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const recordYellowCard = async (data) => {
    try {
        const response = await api.post('/referee-give-card', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
