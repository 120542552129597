import axios from "axios";
import api from "..";

export const login = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/login`, payload,
  );
};

export const sendOtp = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/send-otp`, payload,
  );
};

export const verifyOtp = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/verify-otp`, payload,
  );
};

export const resendOtp = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/resend-otp`, payload,
  );
};

export const signup = (body) => {
  return api.post(
    `/auth/signup`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const changePassword = ({ id, oldPassword, newPassword }) => {
  return api.post(`/auth/change-password`, { id, oldPassword, newPassword });
};

export const sendVerificationCode = (email) => {
  return api.post(
    `/auth/verification-email`,
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const verifyCode = (email, code) => {
  return api.post(
    `/auth/verification-email/verify`,
    { email, code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const exhangeAuthCode = (code) => {
  return api.post(
    `/auth/verify-google-login`,
    { code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};