import React from 'react'
import { CBadge, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { useNavigate } from 'react-router-dom';

export default function PlayersList({players, bookingData}) {
    const navigate = useNavigate();

    const handleRowClick = (playerData) => {
        if(playerData.status === 'active') return
        navigate(`/referee/${bookingData?._id}/${playerData?.player?._id}`)
    }

  return (
    <CRow className="mt-4 px-2">
    <CCol>
      <CTable>
        <CTableHead style={{ backgroundColor: "#00B894", color: "#F9F9F9" }}>
          <CTableRow style={{fontSize: '14px'}}>
            <CTableHeaderCell scope="col">Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Status</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody style={{fontSize: '14px'}}>
          {players.map((playerData, index) => (
            <CTableRow key={index} style={{ cursor: "pointer" }} onClick={() => handleRowClick(playerData)}>
              <CTableDataCell>
                {playerData.player?.name || "Unknown"}
              </CTableDataCell>
              <CTableDataCell>
                {playerData.player?.email || "N/A"}
              </CTableDataCell>
              <CTableDataCell>
                <CBadge
                  color={
                    playerData.status === "rejected"
                      ? "danger"
                      : "success"
                  }
                >
                  {playerData.status}
                </CBadge>
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </CCol>
  </CRow>
  )
}
