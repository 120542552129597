import React, { useState } from 'react'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'

export default function TabView({ tabs }) {
    const [activeKey, setActiveKey] = useState(0)
    return (
        <>
            <CNav variant="tabs" role="tablist" style={{ border: 0, justifyContent: "center"}}>
                {tabs.map((tab, index) => (
                    <CNavItem key={index}>
                        <CNavLink
                            active={activeKey === index}
                            onClick={() => setActiveKey(index)}
                            style={{
                                width: `calc((90vw) / ${tabs?.length || 1})`,
                                textAlign: "center",
                                color: activeKey === index ? "#F9F9F9" : "#00B894",
                                backgroundColor: activeKey === index ? "#00B894" : "#F9F9F9",
                                borderRadius: index === 0 ? "8px 0 0 8px" : index === tabs.length - 1 ? "0 8px 8px 0" : "0",
                                border: "1px solid #00B894",
                                fontWeight: "bold",
                            }}
                        >
                           {activeKey === index && tab?.icon &&  tab?.icon} {activeKey !== index && tab?.selectedIcon &&  tab?.selectedIcon} {' '}  {tab.title}
                        </CNavLink>
                    </CNavItem>
                ))}
            </CNav>
            <CTabContent>
                {tabs.map((tab, index) => (
                    <CTabPane key={index} active={activeKey === index} visible={activeKey === index}>
                        {tab.component}
                    </CTabPane>
                ))}
            </CTabContent>
        </>
    )
}
