import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useMemo } from 'react';

const MessageCard = ({ item, reactions, isOwn, message, time, user = {}, file = null }) => {
    const MessageContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: isOwn ? 'flex-end' : 'flex-start',
        marginBottom: '15px',
        width: '100%',
    }));

    const MessageBox = styled(Box)(({ theme }) => ({
        padding: '10px 15px',
        borderRadius: '15px',
        color: isOwn ? '#fff' : '#000',
        backgroundColor: isOwn ? '#00B894' : 'white',
        borderTopRightRadius: isOwn ? '0' : '15px',
        borderTopLeftRadius: isOwn ? '15px' : '0',
        position: 'relative',
    }));

    const TimeText = styled(Typography)(({ theme }) => ({
        fontSize: '0.75rem',
        color: isOwn ? '#d1e8ff' : '#6c757d',
        textAlign: isOwn ? 'right' : 'left',
        marginTop: '5px',
    }));

    const NameText = styled(Typography)(({ theme }) => ({
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: isOwn ? '#d1e8ff' : '#00B894',
        marginBlock: '2px'
    }));

    const ReactionsContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: isOwn ? 'flex-end' : 'flex-start',
        paddingTop: '5px',
        position: 'relative',
    }));

    const ReactionItem = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        fontSize: '0.875rem',
    }));

    const FileNameText = styled(Typography)(({ theme }) => ({
        fontSize: '0.875rem',
        color: isOwn ? '#d1e8ff' : '#00B894',
        marginTop: '5px',
        wordBreak: 'break-all',
        textAlign: 'center'
    }));

    const fileIcon = useMemo(() => {
        if (!file) return null;

        const fileName = file.name;
        const isImage = fileName.match(/\.(jpeg|jpg|gif|png|svg)$/i);

        return (
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                {isImage ? (
                    <img src={file?.url} alt="file" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                ) : (
                    <IconButton onClick={() => window.open(file?.url, '_blank')}>
                        <InsertDriveFileIcon />
                    </IconButton>
                )}
                <FileNameText>{fileName}</FileNameText>
            </Box>
        );
    }, [file]);

    return (
        <MessageContainer>
            {!isOwn && <Avatar src={user?.profilePic} sx={{ marginRight: '10px' }} />}
            <Box>
                {!isOwn && <NameText>{user?.name}</NameText>}
                <MessageBox>
                    {message && <Typography variant="body2" gutterBottom>{message}</Typography>}
                    {fileIcon}
                    <TimeText>{time}</TimeText>
                </MessageBox>
                <ReactionsContainer>
                    {reactions &&
                        Object.entries(reactions).map(([emoji, reactions]) => (
                            <ReactionItem key={emoji}>
                                <span>{emoji}</span>
                                <span style={{ marginLeft: '3px' }}>{reactions.length}</span>
                            </ReactionItem>
                        ))}
                </ReactionsContainer>
            </Box>
            {isOwn && <Avatar src={user?.profilePic} sx={{ marginLeft: '10px' }} />}
        </MessageContainer>
    );
};

export default MessageCard;
