import React from "react";
import { CModal, CModalBody } from "@coreui/react";

import classes from "./payment.module.css";
import clsx from "clsx";

const PopUpModal = ({ visible, setVisible, bodyStyle = {}, onClose = () => setVisible(false), children , customClass }) => {
  return (
    <>
      <CModal
        alignment="center"
        visible={visible}
        onClose={onClose}
        className={clsx(classes.Content_Modal, classes.custom_modal_size,customClass)}
      >
        <CModalBody style={bodyStyle} className={classes.Main_Modal}>
          {children}
        </CModalBody>
      </CModal>
    </>
  );
};

export default PopUpModal;
