import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PageTitleHeader from "../../Components/Header/PageTitleHeader";

import CardSearchIcon from "../../assets/card_search_icon.svg";

import classes from './refund.module.css'

export default function Refund() {

  return (
    <>
      <div className={classes.header_container}>
        <PageTitleHeader title="Payments" />
      </div>
      <div className={classes.container_body}>
        <div className={classes.NotFoundPage}>
          <img width={51} src={CardSearchIcon} alt="calendar" />
          <p>Your Payments</p>
          <p>When you book a match, you can get information here to track your payments and refunds</p>
        </div>
      </div>
    </>
  );
}
