import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CBadge,
  CCardTitle,
  CCardText,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CCarousel,
  CCarouselItem,
  CImage,
  CCardSubtitle,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";
import dayjs from "dayjs";
import classes from "../../../src/Components/CardComponents/PitchCard.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import PageTitleHeader from "../../Components/Header/PageTitleHeader";
import backgroundPlaceholder from "../../assets/backgroundPlaceholder.png";
import ScoreBoard from "./ScoreBoard";
import { postBookingById } from "../../Api/services/match";
import PlayersList from "./PlayersList";
import TabView from "../../Components/TabView";

const RefereeDetails = () => {
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [needsRefetch, setNeedsRefetch] = useState(false);
  const navigate = useNavigate();
  const routerId = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postBookingById(
          routerId?.pathname?.split("/")[2]
        );
        setBookingData(response?.booking);
      } catch (error) {
        console.error("Failed to fetch booking by ID:", error);
        setError("Failed to load booking details.");
      } finally {
        setLoading(false);
        setNeedsRefetch(false); // Reset the refetch flag after data is loaded
      }
    };

    fetchData();
  }, [needsRefetch]); // Dependency on needsRefetch

  const navigateToResults = () => {
    navigate(`/`);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!bookingData) {
    return <p>No booking data available.</p>;
  }

  const { pitch, players, referee } = bookingData;

  return (
    <>
      <div className={classes.header_container}>
        <PageTitleHeader title="Referee" onClick={navigateToResults} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CCarousel controls>
            {pitch?.images?.length > 0 ? (
              pitch?.images?.map((item, index) => (
                <CCarouselItem key={index}>
                  <CImage className="d-block w-100" src={item} alt="Slide" />
                </CCarouselItem>
              ))
            ) : (
              <CCarouselItem>
                <CImage
                  className="d-block w-100"
                  src={backgroundPlaceholder}
                  alt="Slide"
                />
              </CCarouselItem>
            )}
          </CCarousel>
          <div
            className={`${classes.booking_container} ${classes.homeContainer}`}
          >
            <div className={classes.booking_body}>
              <CCard>
                <CCardBody>
                  <CCardTitle>{pitch?.pitchName}</CCardTitle>
                  <CCardSubtitle className="mb-2 text-body-secondary">
                    {pitch?.pitchType}
                  </CCardSubtitle>
                </CCardBody>
              </CCard>
                  <CListGroup flush>
                      <CListGroupItem>
                        <h6 className="mt-3 mb-0">Match Details</h6>
                      </CListGroupItem>
                        <CListGroupItem>
                        Date: {dayjs(bookingData?.slot?.start).format("DD-MM-YYYY")}
                        </CListGroupItem>
                        <CListGroupItem>
                          Time: {dayjs(bookingData?.slot?.start).format("hh:mm A")}
                        </CListGroupItem>
                        <CListGroupItem>
                        Location: {pitch?.address?.city}, {pitch?.address?.country}
                        </CListGroupItem>
                    </CListGroup>
            </div>
          </div>
        </>
      )}
      <div className={`${classes.homeContainer} mt-4`}>
        {/* Players Information */}
        <TabView
          tabs={[
            {
              title: "Players",
              component: (
                <div className={classes.homeContainer}>
                  <PlayersList players={players} bookingData={bookingData} />
                </div>
              ),
            },
            {
              title: "Score",
              component: (
                <div className={classes.homeContainer}>
                  <ScoreBoard players={players} bookingData={bookingData} setNeedsRefetch={setNeedsRefetch}/>
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default RefereeDetails;
