import * as Yup from "yup";
import valid from "card-validator"; //import statement
// Custom validation to check the host name
const validateHostName = (value) => {
  const allowedHost = "gmail.com"; // Set the correct host name
  if (value && value.includes("@")) {
    const hostName = value.split("@")[1];
    if (hostName !== allowedHost) {
      return false;
    }
  }
  return true;
};
export const UserRegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Incorrect email address, please check and try again")
    .required("Email is required"),
  password: Yup.string()
    .min(6 || 8, "Password must be minimum 6 or 8 symbols")

    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Must be at least 1 uppercase, 1 digit, 1 special symbol etc."
    )
    .required("Weak password, please check and try again"),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Passwords don’t match, please check and try again"
    )
    .required("Passwords don’t match, please check and try again"),
});

export const PatientRegistrationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  age: Yup.number()
    .required("Age is required")
    .min(1, "Age must be greater than 0"),
});

export const SecondStepRegistrationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  birthDate: Yup.string().required("Birth date is required")
});

export const updatePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password must include at least 8 characters."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Incorrect email address, please check and try again")
    .required("Email is not registered yet")
    .test(
      "host-name",
      "Incorrect host name, please use 'gmail.com'",
      validateHostName
    ),
  password: Yup.string()
    .required("Incorrect password address, please check and try again"),
});

export const HomeSearchSchema = Yup.object().shape({
  treatmentType: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  date: Yup.string().required("Required"),
  asap: Yup.string().required("Required"),
  insurance: Yup.string().required("Required"),
});
export const ResultSchema = Yup.object().shape({
  treatmentType: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  time: Yup.string().required("Required"),
  insurance: Yup.string().required("Required"),
});
export const BookingSchema = Yup.object().shape({
  appointment_type: Yup.string().required("Required"),
  appointment_time: Yup.string().required("Required"),
  insurance: Yup.string().required("Required"),
});

export const CardPaymentSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .test(
      "test-number",
      "Card number is invalid",
      (value) => valid.number(value).isValid
    )
    .required("Required"),
  expDate: Yup.string().required("Required"),
  cvv: Yup.string()
    .test(
      "test-cvc",
      "Credit Card cvc is invalid",
      (value) => valid.cvv(value).isValid
    )
    .required("Required"),
});
