import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Chat, MemberList as Members } from '@pubnub/react-chat-components';

import PageTitleHeader from '../../Components/Header/PageTitleHeader';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00b894',
        },
        secondary: {
            main: '#ffffff',
        },
    },
});

function MemberList() {
    const params = useParams();
    const { state } = useLocation();
    const [team, setTeam] = useState([]);

    useEffect(() => {
        const initialMembers = state.members.map((item) => {
            let player = item?.player || {};
            return {
                id: player._id,
                name: player?.name,
                profileUrl: player?.photo,
                team: item?.team,
            };
        });

        setTeam(initialMembers);
    }, [state.members]);

    const renderTeamMembers = (teamMembers, team) => {
        let teamList = teamMembers?.filter((member) => member?.team === team);
        return (
            teamList?.length === 0 ? (
                <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '20px' }}>
                    No members in Team {team}
                </Typography>
            ) : (
                <Members
                    members={teamList}
                    onMemberClicked={(member) => console.log(member)}
                />
            )
        )
    };

    return (
        <ThemeProvider theme={theme}>
            <PageTitleHeader title={"Members"} />
            <Box sx={{ padding: '20px 10px', minHeight: '100dvh', backgroundColor: '#F9F9F9' }}>
                <Chat currentChannel={params?.channel}>
                    <Box sx={{ marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Team A</Typography>
                        </Box>
                        {renderTeamMembers(team, 'A')}
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Team B</Typography>
                        </Box>
                        {renderTeamMembers(team, 'B')}
                    </Box>
                </Chat>
            </Box>
        </ThemeProvider>
    );
}

export default MemberList;
