import { SET_SCREEN_VIEW } from "../../Constants";

const currentView = window.innerWidth < 768;
export const DashboardReducer = (state = { isMobile: currentView }, action) => {
  switch (action.type) {
    case SET_SCREEN_VIEW:
      return { ...action.payload };
    default:
      return state;
  }
};
