import { SET_BOOKING_DETAILS } from "../../Constants";

export const BookingReducer = (state = null, action) => {
  switch (action.type) {
    case SET_BOOKING_DETAILS:
      if (action?.payload) {
        return { ...action?.payload };
      } else {
        return null;
      }
    default:
      return state;
  }
};
