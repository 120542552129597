import { SAVE_USER_INFO, UPDATE_USER_INFO, LOGOUT } from "../../Constants";

export const UserReducer = (state = {}, action) => {
    switch (action.type) {
        case SAVE_USER_INFO:
            return { ...action.payload };
        case UPDATE_USER_INFO:
            return { ...state, ...action.payload };
        case LOGOUT:
            return null;
        default:
            return state;
    }
};
