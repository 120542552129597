import { SET_LOGIN_MODAL } from "../../Constants";

export const LoginReducer = (state = { loginShow: false }, action) => {
  switch (action.type) {
    case SET_LOGIN_MODAL:
      return { ...action.payload };
    default:
      return state;
  }
};
