// pubnub-config.js
import PubNub from 'pubnub';
import dayjs from 'dayjs';

const pubnub = new PubNub({
    publishKey: 'pub-c-5cc50acc-413c-4269-a6d7-3d92aba1118c',
    subscribeKey: 'sub-c-67c344d2-66cc-47a6-a88e-f33ff354133e',
    uuid: JSON.parse(localStorage.getItem('user') || '{}')?.uid || 'guest',
});

export const CreateChannel = async ({ channelId, pitchProvider, createdBy, users }) => {
    try {
        console.log('Creating channel:', channelId, pitchProvider, createdBy, users);
        let usersList = [...users, pubnub.getUUID()];

        await pubnub.channelGroups.addChannels({
            channels: [channelId],
            channelGroup: pubnub.getUUID()
        });

        await pubnub.objects.setChannelMetadata({
            channel: channelId,
            data: {
                name: channelId,
                description: `Created on ${dayjs().format('DD/MM/YYYY')}`,
                custom: {
                    pitchProvider,
                    createdBy,
                    users: JSON.stringify(usersList),
                    date: `${new Date()}`
                }
            }
        });
    } catch (e) {
        console.log(e);
    }
}

export const getChannelData = async (channel) => {
    try {
        const response = await pubnub.objects.getChannelMetadata({ channel: channel });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export const deleteChannel = async (channel) => {
    try {
        await pubnub.objects.setChannelMetadata({
            channel: channel,
            data: {
                name: 'deleted',
                description: ``,
                custom: {}
            }
        });
        await pubnub.channelGroups.removeChannels({
            channels: [channel],
            channelGroup: pubnub.getUUID()
        });
    } catch (e) {
        console.log(e);
    }
}

export default pubnub;