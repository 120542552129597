import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { UserReducer } from "./Reducers/UserReducer";
import { SignUpReducer } from "./Reducers/SignUpReducer";
import { LoginReducer } from "./Reducers/LoginReducer";
import { DashboardReducer } from "./Reducers/DashboardReducer";
import { BookingReducer } from "./Reducers/Booking";

const INITIAL_STATE = {
    user: null,
};

const reducer = combineReducers({
    user: UserReducer,
    signup: SignUpReducer,
    login: LoginReducer,
    dashboard: DashboardReducer,
    booking: BookingReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    INITIAL_STATE,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;