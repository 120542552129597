import React from 'react'
import classes from './Loader.module.css'

export default function Loader() {
    return (
        <div className={classes.loaderContainer}>
            <div className={classes["lds-ring"]}><div></div><div></div><div></div><div></div></div>
        </div>
    )
}
