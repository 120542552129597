// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCl1NHrp6DULXGaMeo_tyIzKeMsACa3_LU",
  authDomain: "kicks-app-pwa.firebaseapp.com",
  projectId: "kicks-app-pwa",
  storageBucket: "kicks-app-pwa.appspot.com",
  messagingSenderId: "282837265984",
  appId: "1:282837265984:web:cde2d3ed3837c8c301f9a5"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
export {auth, googleProvider, facebookProvider};